:root {
  --color-black: #000;
  --color-blue: #4a2dff;
  --color-red: #fc000de3;
  --color-border: #666;
  --color-green: #d5ee3e;
  --color-offblack: #222;
  --color-offwhite: #ccc;
  --color-white: #fff;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-s: 1.2;
  --line-height-m: 1.5;
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 36px;
  --border-radius: 2px;
  --transition: 200ms cubic-bezier(0.22, 1, 0.36, 1);
  --font-family-mono: "Fira Mono", monospace;
  --font-family-sans: "ig", sans-serif;
  --z-index-header: 900;
  --z-index-modal: 1000;
  --container-s: 480px;
  --container-m: 600px;
  --container-l: 940px;
}

@custom-media --viewport-s (min-width: 400px);
@custom-media --viewport-m (min-width: 768px);
@custom-media --viewport-l (min-width: 1024px);
@custom-media --viewport-xl (min-width: 1280px);

@font-face {
  font-display: optional;
  font-family: "ig";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/ig-700.woff2") format("woff2");
}

@font-face {
  font-display: optional;
  font-family: "Fira Mono";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/fira-mono-400.woff2") format("woff2");
}

@font-face {
  font-display: optional;
  font-family: "Fira Mono";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/fira-mono-500.woff2") format("woff2");
}

@font-face {
  font-display: optional;
  font-family: "Fira Mono";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/fira-mono-700.woff2") format("woff2");
}

::selection {
  background: var(--color-green);
  color: var(--color-black);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-black);
  color: var(--color-offwhite);
  font-family: var(--font-family-mono);
  margin: 0;
}

button,
input,
select {
  font-family: var(--font-family-mono);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-sans);
  font-weight: var(--font-weight-bold);
}
